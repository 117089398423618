import {useEffect} from "react";
import * as React from "react";

const CookieConsent = () => {
    useEffect(() => {
        if(typeof window !== 'undefined') {
            const cs = window.initCookieConsent()
            cs.run({
                autorun: true,
                current_lang: 'it',
                autoclear_cookies: true,
                force_consent: true,
                gui_options: {
                    consent_modal: {
                        layout: 'bar',               // box/cloud/bar
                        position: 'bottom center',     // bottom/middle/top + left/right/center
                        transition: 'slide',           // zoom/slide
                        swap_buttons: false            // enable to invert buttons
                    }
                },
                page_scripts: true,
                languages: {
                    it: {
                        consent_modal: {
                            title: 'Utilizziamo i cookie',
                            description: `Il Sito utilizza <span style="font-weight: 600">cookie tecnici</span> per i quali non è necessario acquisire il consenso dell’utente
                                                        e potrà, esclusivamente previa acquisizione del consenso, utilizzare anche cookie analitici
                                                        non equiparabili ai cookie tecnici, e <span style="font-weight: 600">cookie di profilazione</span> (anche di terze parti) per inviare
                                        messaggi pubblicitari in linea con le preferenze.<br>Seleziona <span style="font-weight: 600">Gestisci Cookie</span> per conoscere le caratteristiche dei vari cookie utilizzati e
                                impostare o rivedere i consensi in precedenza espressi. Per approfondimenti, consulta
                                anche la <a aria-label="Cookie policy" class="cc-link privacy-modal-activator">Cookie Policy</a> del sito`,
                            primary_btn: {
                                text: 'Accetta',
                                role: 'accept_all'
                            },
                            secondary_btn: {
                                text: 'Gestisci Cookie',
                                role: 'settings'
                            }
                        },
                        settings_modal: {
                            title: 'Preferenze cookie',
                            save_settings_btn: 'Salva impostazioni',
                            accept_all_btn: 'Accetta tutto',
                            reject_all_btn: 'Rifiuta tutto',
                            cookie_table_headers: [
                                {col1: 'Servizio'},
                                {col2: 'Finalità'},
                                {col3: 'Nome e durata'},
                                {col4: 'Informativa'}
                            ],
                            blocks: [
                                {
                                    title: 'Utilizzo dei cookie',
                                    description: 'Utilizziamo i cookie per garantire le funzionalità di base del sito Web e per migliorare la tua esperienza online. Può scegliere per ogni categoria di attivarli/disattivarli quando vuole.'
                                }, {
                                    title: 'Cookie tecnici di prima parte',
                                    description: 'Questi cookie sono essenziali per il corretto funzionamento del suo sito web. Senza questi cookie il sito non funzionerebbe correttamente.',
                                    toggle: {
                                        value: 'necessary',
                                        enabled: true,
                                        readonly: true
                                    },
                                    cookie_table: [
                                        {
                                            col1: 'TAPPEZZERIA ORSILLI',
                                            col2: 'Questo cookie viene utilizzato per acquisire il consenso ai sensi della normativa in materia di cookie',
                                            col3: 'cc_cookie (1 ora)',
                                            col4: 'n.d',
                                            is_regex: true
                                        }
                                    ]
                                }, {
                                    title: 'Cookie di profilazione di terza parte',
                                    description: 'Questi cookie raccolgono informazioni su come utilizza il sito web, quali pagine ha visitato e su quali link ha cliccato. Tutti i dati sono anonimi e non possono essere utilizzati per identificarla.',
                                    toggle: {
                                        value: 'analytics',
                                        enabled: false,
                                        readonly: false
                                    },
                                    cookie_table: [
                                        {
                                            col1: 'Google',
                                            col2: `Il cookie _ga è un cookie che permette di numerare le
                                                                        visite del sito e quindi conoscere l’efficacia dello stesso`,
                                            col3: '_ga (2 anni)',
                                            col4: '<a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>',
                                            is_regex: true
                                        },
                                        {
                                            col1: 'Google',
                                            col2: `Il cookie _gat_gtag è un cookie che permette il riconoscimento anonimo dell'utente`,
                                            col3: '_gat_gtag_UA114445719_1 (1 minuto dall’ultima modifica)',
                                            col4: '<a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>',
                                            is_regex: true
                                        },
                                        {
                                            col1: 'Google',
                                            col2: 'Il cookie _gid è un cookie adibito all’identificazione della sessione',
                                            col3: '_gid (1 giorno)',
                                            col4: '<a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>',
                                            is_regex: true
                                        },
                                        {
                                            col1: 'Google',
                                            col2: 'Il cookie _ga_ è un cookie utilizzato per limitare le richieste.',
                                            col3: '_ga_CSL5PD7EH5 (10 minuti dall’ultima modifica)',
                                            col4: '<a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>',
                                            is_regex: true
                                        },
                                        {
                                            col1: 'Google',
                                            col2: 'Il cookie _gcl_au è utilizzato dal servizio AdSense di Google per migliorare efficienza e pertinenza degli annunci proposti.',
                                            col3: '_gcl_au (90 giorni dall’ultima modifica)',
                                            col4: '<a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>',
                                            is_regex: true
                                        },
                                        {
                                            col1: 'Facebook',
                                            col2: 'Il cookie _fbp identifica i browser al fine di fornire servizi pubblicitari o di analisi dei dati del sito.',
                                            col3: '_fbp (90 giorni dall’ultima modifica)',
                                            col4: '<a href="https://policies.google.com/privacy" target="_blank">https://www.facebook.com/privacy/policy</a>',
                                            is_regex: true
                                        }

                                    ]
                                }
                            ]
                        }
                    }
                }
            });
        }
    }, [])
    return (
        <></>
    )
}
export default CookieConsent

